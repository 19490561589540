import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import SkuTile from "../../components/SkuTile"
import Image from "../../components/Image"

import styles from "./story.module.scss"

const SS21 = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let updatedSkus = []
    nodes.forEach(style => {
      style.skus.forEach(sku => {
        if (sku.images.plp.length > 0 || sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  return (
    <>
      <SEO
        title="Netting"
        description="Spring Summer '21"
        url={location.pathname}
      />

      <header className={styles.header}>
        <Heading
          tag="h1"
          level="0"
          className={styles.heading}
        >
          Netting
        </Heading>
        {/*<Filters*/}
        {/*  allItems={collectionStyles}*/}
        {/*  items={filteredStyles}*/}
        {/*  setItems={setFilteredStyles}*/}
        {/*  setFilteredColor={setFilteredColor}*/}
        {/*  collectionTitle={productCollection.title}*/}
        {/*/>*/}
      </header>

      <ul className={styles.grid}>
        {allSkus.map((sku, i) => (
          <SkuTile
            key={i}
            index={i}
            style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
            sku={sku}
            customStyles={styles}
            origin={`collection-ss21`}
          />
        ))}
        <li className={styles.storyItem41}>
          <Image
            image={{
              desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Netting_1_1320x.jpg?v=1614815975",
              mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Netting_1_850x.jpg?v=1614815975",
            }}
            altText="Netting Story"
          />
        </li>
      </ul>
    </>
  )
}

export default SS21

export const query = graphql`
    query NettingQuery {
        allProductStyle(filter: {handle: {in: [
            "next-step-fabric-flats",
            "new-world-pumps",
        ]}}) {
            nodes {
                ...PLPStyleFragment
            }
        }
    }
`
